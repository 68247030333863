import React, { useState } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  Input,
  Typography,
  Upload,
  Divider,
  Modal,
} from "antd";
import { useNavigate } from "react-router-dom";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "../App.css";
import { styles, color } from "../helpers/styles";
import { DemoBox } from "../helpers/component";
import info from "../image/info.svg";
import imageUpload from "../image/imageUpload.png";
import { postJson, patchJson, API_URL } from "../helpers/api";
import Slip from "./Slip";

const { Text } = Typography;

const ImportNcb = ({ user, postBack }) => {
  const [page, setPage] = useState("slip");
  const [loading, setLoading] = useState(false);
  const [checkPdf, setCheckPdf] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [slipList, setSlipList] = useState([]);
  let navigate = useNavigate();

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const mapValues = async (file, type) => {
    const base64 = await getBase64(file.originFileObj);
    const value = {
      type: type,
      key: file.name,
      value: base64,
    };
    return value;
  };

  const onFinish = async (values) => {
    setLoading(true);
    const postValues = [];
    if (values?.fileImage) {
      for (const file of values.fileImage.fileList) {
        const value = await mapValues(file, "image");
        postValues.push(value);
      }
    }
    if (values?.filePdf) {
      const { passwordPdf } = values;
      const file = values.filePdf.fileList[0];
      const value = await mapValues(file, "pdf");
      postValues.push(value);
      if (passwordPdf)
        postValues.push({
          type: "string",
          key: "password",
          value: passwordPdf,
        });
    }
    if (slipList.length > 0) {
      for (const file of slipList) {
        const value = await mapValues(file, "image");
        postValues.push(value);
      }
    }

    await postJson(`${postBack}`, postValues);
    await patchJson(`${API_URL}/debtplans/status`, {
      mobileNo: user.mobileNo,
      status: "รอตรวจ NCB",
    });
    navigate("/success");
    window.ReactNativeWebView.postMessage("success");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const showPage = {
    slip: (
      <>
        <Slip slipList={slipList} setSlipList={setSlipList} setPage={setPage} />
      </>
    ),
    ncb: (
      <>
        <div className="ImportNcb" style={styles.main}>
          <Row>
            <Col span={3} align="left">
              <ArrowLeftOutlined
                onClick={() => {
                  setPage("slip");
                }}
              />
            </Col>
            <Col span={18} align="center">
              <Text
                style={{
                  color: "#51546E",
                  ...styles.text1,
                }}
              >
                ส่งสลิปเงินเดือน และผลข้อมูลเครดิต
              </Text>
            </Col>
          </Row>
          <br />
          <Row
            style={{
              backgroundColor: "#F86363",
              borderRadius: "8px",
            }}
          >
            <Col>
              <div
                style={{
                  paddingTop: 14,
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              >
                <Image src={info} />
              </div>
            </Col>
            <Col>
              <DemoBox>
                <Text
                  style={{
                    color: "#FFFFFF",
                    ...styles.text3,
                  }}
                >
                  เลือกแนบไฟล์ หรือรูปภาพผลข้อมูลเครดิต
                </Text>
              </DemoBox>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Text style={styles.text1}>
                แนบไฟล์ PDF ข้อมูลเครดิต พร้อมกรอกรหัสเปิดไฟล์์
              </Text>
            </Col>
          </Row>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ paddingTop: "15px" }}
          >
            <Row justify="center">
              <Col span={24}>
                <Form.Item name={"filePdf"} style={{ paddingTop: "5px" }}>
                  <Upload
                    accept=".pdf"
                    beforeUpload={(file) => {
                      const reader = new FileReader();

                      reader.onload = (e) => {
                        console.log(e.target.result);
                      };
                      reader.readAsText(file);

                      // // Prevent upload
                      if (!["application/pdf"].includes(file.type)) {
                        setCheckPdf(false);
                        return true;
                      } else {
                        setCheckPdf(true);
                        return false;
                      }
                    }}
                    listType="picture"
                    onRemove={() => setCheckPdf(false)}
                  >
                    {!checkPdf ? (
                      <Button
                        style={{ borderColor: color.primary, height: "48px" }}
                        icon={
                          <UploadOutlined style={{ color: color.primary }} />
                        }
                        block
                      >
                        <Text style={{ ...styles.text1, color: color.primary }}>
                          แนบไฟล์ PDF
                        </Text>
                      </Button>
                    ) : (
                      ""
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={"passwordPdf"}
                  rules={[
                    {
                      required: checkPdf ? true : false,
                      message: "กรุณากรอกรหัสเปิดไฟล์",
                    },
                  ]}
                >
                  <Input
                    style={{ ...styles.input, padding: "10px", height: "48px" }}
                    placeholder="กรอกรหัสเปิดไฟล์"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col>
                <Text style={styles.text1}>
                  แนบรูปข้อมูลเครดิตให้ครบทุกหน้า
                </Text>
              </Col>
            </Row>
            <br />
            <div style={{ textAlign: "left" }}>
              <Form.Item
                name={"fileImage"}
                rules={[
                  {
                    required: !checkPdf ? true : false,
                    message: "กรุณาอัพโหลดรูปภาพ",
                  },
                ]}
              >
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  accept=".png,.jpeg"
                  listType="picture-card"
                  fileList={fileList}
                  multiple
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={async (file) => {
                    return false;
                  }}
                >
                  <Text style={{ ...styles.text1, color: color.primary }}>
                    แนบรูป
                  </Text>
                </Upload>
              </Form.Item>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
            <Divider />
            <Form.Item style={{ margin: "5px" }}>
              <Button
                style={{
                  height: 50,
                  background:
                    fileList.length > 0 || checkPdf
                      ? color.primary
                      : color.grey,
                  color: "#FCFCFC",
                  borderRadius: "10px",
                  ...styles.text5,
                }}
                disabled={fileList.length > 0 || checkPdf ? false : true}
                block
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                บันทึก
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    ),
  };

  return showPage[page];
};

export default ImportNcb;
