import { useEffect, useState } from "react";
import { Row, Col, Form, Button, Typography, Select } from "antd";
import "../App.css";
import {
  PlusOutlined,
  CloseCircleFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { styles } from "../helpers/styles";
import expends from "../image/debtsInformal.svg";
import HeaderIcon from "../components/HeaderIcon";
import InputNumber from "../components/InputNumber";
import InputText from "../components/InputText";

const { Text } = Typography;
const { Option } = Select;

const DebtsInformal = ({ setPage, debts, submitDebt }) => {
  const [loading, setLoading] = useState(false);
  const [calAmount, setCalAmount] = useState([
    { name: null, interest: 0, actualPayment: 0, osb: 0 },
  ]);

  const onFinish = async (values) => {
    setLoading(true);
    const { debtsInformal } = values;
    if (debtsInformal.length > 0) {
      const mapDebts = debtsInformal.map((debt) => {
        const { amount, actualPayment, compound } = debt;
        return {
          ...debt,
          compound: compound || "เดือน",
          minpayRate: ((actualPayment * 100) / amount).toFixed(2),
          type: "นอกระบบไม่หมุน",
          canStop: false,
          isPastDue: false,
        };
      });
      submitDebt(mapDebts);
      window.scrollTo(0, 0);
    } else {
      submitDebt();
      window.scrollTo(0, 0);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="DebtsInformal" style={styles.main}>
      <Row>
        <Col span={6} align="left">
          <ArrowLeftOutlined
            onClick={() => {
              setPage("debtsOther");
            }}
          />
        </Col>
        <Col span={12} align="center">
          <Text
            style={{
              color: "#51546E",
              ...styles.text1,
            }}
          >
            หนี้สิน (3/3)
          </Text>
        </Col>
      </Row>

      <HeaderIcon icon={expends} text="หนี้นอกระบบ" />

      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.List name="debtsInformal" initialValue={debts}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  style={{
                    border: "2px solid #DEE8F8",
                    borderRadius: "16px",
                    padding: "5px",
                    marginTop: "10px",
                  }}
                >
                  <Row span={24} style={{ marginTop: "5px" }}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Text
                        style={{
                          color: "#51546E",
                          ...styles.text1,
                        }}
                      >
                        รายการที่ {name + 1}
                      </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <CloseCircleFilled
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "5px", height: 55 }}>
                    <Col span={24}>
                      <Form.Item {...restField} name={[name, "name"]}>
                        <InputText
                          style={styles.input}
                          placeholder="กรอกชื่อหนี้"
                          keyArray={key}
                          ArrayData={calAmount}
                          setArrayData={setCalAmount}
                          type="name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 55 }}>
                    <Col span={16}>
                      <Form.Item
                        {...restField}
                        name={[name, "intRate"]}
                        rules={[
                          {
                            required:
                              calAmount[key]?.name ||
                              calAmount[key]?.osb ||
                              calAmount[key]?.actualPayment
                                ? true
                                : false,
                            message: "ใส่ดอกเบี้ย",
                          },
                        ]}
                      >
                        <InputNumber
                          style={styles.input}
                          placeholder="ดอกเบี้ย"
                          suffix="ร้อยละ (%)"
                          keyArray={key}
                          ArrayData={calAmount}
                          setArrayData={setCalAmount}
                          type="interest"
                          maxValue={99}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} style={{ paddingLeft: "5px" }}>
                      <Form.Item {...restField} name={[name, "compound"]}>
                        <Select
                          placeholder={
                            <div style={{ ...styles.normal1, marginTop: 3 }}>
                              ต่อวัน
                            </div>
                          }
                          style={styles.normal1}
                          defaultValue="เดือน"
                        >
                          <Option value="วัน">
                            <Text style={styles.normal1}>ต่อวัน</Text>
                          </Option>
                          <Option value="เดือน">
                            <Text style={styles.normal1}>ต่อเดือน</Text>
                          </Option>
                          <Option value="ปี">
                            <Text style={styles.normal1}>ต่อปี</Text>
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 55 }}>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "actualPayment"]}
                        rules={[
                          {
                            required:
                              calAmount[key]?.name ||
                              calAmount[key]?.interest ||
                              calAmount[key]?.osb
                                ? true
                                : false,
                            message: "ใส่ยอดชำระต่อเดือน",
                          },
                        ]}
                      >
                        <InputNumber
                          style={styles.input}
                          placeholder="ยอดชำระต่อเดือน"
                          suffix="บาท"
                          keyArray={key}
                          ArrayData={calAmount}
                          setArrayData={setCalAmount}
                          type="actualPayment"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 55 }}>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "amount"]}
                        rules={[
                          {
                            required:
                              calAmount[key]?.name ||
                              calAmount[key]?.interest ||
                              calAmount[key]?.actualPayment
                                ? true
                                : false,
                            message: "ใส่ยอดหนี้คงเหลือ",
                          },
                        ]}
                      >
                        <InputNumber
                          style={styles.input}
                          placeholder="ยอดหนี้คงเหลือ"
                          suffix="บาท"
                          keyArray={key}
                          ArrayData={calAmount}
                          setArrayData={setCalAmount}
                          type="osb"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
              <Form.Item style={{ paddingTop: "15px", textAlign: "left" }}>
                <Button
                  style={{
                    color: "#164FA3",
                    border: "2px solid #164FA3",
                    ...styles.text4,
                  }}
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  เพิ่มรายการ
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            style={{
              height: 50,
              background: "#164FA3",
              color: "#FCFCFC",
              borderRadius: "10px",
              ...styles.text5,
            }}
            block
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DebtsInformal;
