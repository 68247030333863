import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Typography,
  Upload,
  Divider,
  Modal,
  Image,
} from "antd";
import "../App.css";
import info from "../image/info.svg";
import { styles, color } from "../helpers/styles";
import { DemoBox } from "../helpers/component";

const { Text } = Typography;

const Slip = ({ slipList, setSlipList, setPage }) => {
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setSlipList(newFileList);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const mapValues = async (file, type) => {
    const base64 = await getBase64(file.originFileObj);
    const value = {
      type: type,
      key: file.name,
      value: base64,
    };
    return value;
  };

  const onFinish = async (values) => {
    setLoading(true);
    const postValues = [];
    if (values?.fileImage) {
      for (const file of values.fileImage.fileList) {
        const value = await mapValues(file, "image");
        postValues.push(value);
      }
    }
    setPage("ncb");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="ImportNcb" style={{ ...styles.main, flex: 1 }}>
      <Row justify="center">
        <Col align="center">
          <Text
            style={{
              color: "#51546E",
              ...styles.text1,
            }}
          >
            ส่งสลิปเงินเดือน และผลข้อมูลเครดิต
          </Text>
        </Col>
      </Row>
      <br />
      <Row
        style={{
          backgroundColor: "#F86363",
          borderRadius: "8px",
        }}
      >
        <Col span={2}>
          <div
            style={{
              paddingTop: 14,
              marginLeft: "5px",
            }}
          >
            <Image src={info} />
          </div>
        </Col>
        <Col span={20}>
          <DemoBox>
            <div
              style={{
                color: "#FFFFFF",
                textAlign: "left",
                marginLeft: "5px",
                ...styles.text3,
              }}
            >
              กรุณาเตรียมสลิปเงินเดือน เดือนล่าสุด และ ผลข้อมูลเครดิต ให้พร้อม
            </div>
          </DemoBox>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Text style={styles.text1}>แนบรูปสลิปเงินเดือน เดือนล่าสุด</Text>
        </Col>
      </Row>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ paddingTop: "15px" }}
      >
        <div style={{ textAlign: "left" }}>
          <Form.Item name={"fileImage"}>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              accept=".png,.jpeg"
              listType="picture-card"
              fileList={slipList}
              multiple
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={async (file) => {
                return false;
              }}
            >
              <Text style={{ ...styles.text1, color: color.primary }}>
                แนบรูป
              </Text>
            </Upload>
          </Form.Item>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </div>
        <Divider />
        <Form.Item style={{ margin: "5px" }}>
          <Button
            style={{
              height: 50,
              background: color.primary,
              color: "#FCFCFC",
              borderRadius: "10px",
              ...styles.text5,
            }}
            disabled={false}
            block
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            ไปต่อ
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Slip;
