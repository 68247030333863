export const styles = {
  main: {
    fontFamily: 'Kanit',
    padding: '16px',
  },
  input: {
    fontFamily: 'Kanit',
    color: '#51546E',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
  },
  text1: {
    fontFamily: 'Kanit',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
  text2: {
    fontFamily: 'Kanit',
    fontWeight: '500',
    fontSize: '22px',
    lineHeight: '32px',
  },
  text3: {
    fontFamily: 'Kanit',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  text4: {
    fontFamily: 'Kanit',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  text5: {
    fontFamily: 'Kanit',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '32px',
  },
  normal1: {
    fontFamily: 'Kanit',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
  },
  normal2: {
    fontFamily: 'Kanit',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
  },
}

export const color = {
  primary: '#164FA3',
  lightBlue: '#22BED2',
  white: '#DDF0FF',
  grey: '#B9BBC5',
}
