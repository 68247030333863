import { useState } from 'react'
import { Form, Row, Col, Input, Typography, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { styles } from '../helpers/styles'
import HeaderIcon from './HeaderIcon'
import InputNumber from '../components/InputNumber'
import { addcomma } from '../helpers/data'

const { Text } = Typography

const OutCome = ({
  pageTitle,
  setPage,
  title,
  icon,
  backPage,
  nextPage,
  expenses,
  setExpenses,
  name,
  submit,
}) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { getFieldValue } = form
  const [sumAmount, setSumAmount] = useState(0)

  const onFinish = async (values) => {
    setLoading(true)
    const mapValues = {}
    Object.keys(values).map((key) => {
      Object.keys(values[key]).map((subKey) => {
        if (values[key][subKey]) mapValues[subKey] = values[key][subKey]
      })
    })

    const _values = {
      ...expenses,
    }
    _values[name] = mapValues
    setExpenses(_values)
    if (submit) submit(mapValues)
    if (nextPage) setPage(nextPage)
    setLoading(false)
    window.scrollTo(0, 0)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div style={styles.main}>
      <Row>
        <Col span={6} align="left">
          {backPage ? (
            <ArrowLeftOutlined
              onClick={() => {
                setPage(backPage)
              }}
            />
          ) : (
            ''
          )}
        </Col>
        <Col span={12} align="center">
          <Text
            style={{
              color: '#51546E',
              ...styles.text1,
            }}
          >
            {pageTitle[0]}
          </Text>
        </Col>
      </Row>

      <HeaderIcon icon={icon} text={pageTitle[1]} />

      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ paddingTop: '15px' }}
        initialValues={expenses}
        align="left"
      >
        <Row style={{ paddingTop: '5px', height: 80 }}>
          <Col span={24}>
            <Text style={styles.text1}>{title[0]} (บาท)</Text>
            <Form.Item name={[name, 'amount1']} style={{ paddingTop: '5px' }}>
              <InputNumber style={styles.input} setData={setSumAmount} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px', height: 80 }}>
          <Col span={24}>
            <Text style={styles.text1}>{title[1]} (บาท)</Text>
            <Form.Item name={[name, 'amount2']} style={{ paddingTop: '5px' }}>
              <InputNumber style={styles.input} setData={setSumAmount} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px', height: 80 }}>
          <Col span={24}>
            <Text style={styles.text1}>{title[2]} (บาท)</Text>
            <Form.Item name={[name, 'amount3']} style={{ paddingTop: '5px' }}>
              <InputNumber style={styles.input} setData={setSumAmount} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px', height: 80 }}>
          <Col span={24}>
            <Text style={styles.text1}>{title[3]} (บาท)</Text>
            <Form.Item name={[name, 'amount4']} style={{ paddingTop: '5px' }}>
              <InputNumber style={styles.input} setData={setSumAmount} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px', height: 80 }}>
          <Col span={24}>
            <Text style={styles.text1}>{title[4]} (บาท)</Text>
            <Form.Item name={[name, 'amount5']} style={{ paddingTop: '5px' }}>
              <InputNumber style={styles.input} setData={setSumAmount} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px', height: 80 }}>
          <Col span={24}>
            <Text style={styles.text1}>{title[5]} (บาท)</Text>
            <Form.Item name={[name, 'amount6']} style={{ paddingTop: '5px' }}>
              <InputNumber style={styles.input} setData={setSumAmount} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={17}>
            <Text style={{ ...styles.text1 }}>{title[6]}</Text>
          </Col>
          <Col span={7} align="right">
            <Text style={styles.text1}>
              {addcomma(
                Number(getFieldValue([name, 'amount1']) || 0) +
                  Number(getFieldValue([name, 'amount2']) || 0) +
                  Number(getFieldValue([name, 'amount3']) || 0) +
                  Number(getFieldValue([name, 'amount4']) || 0) +
                  Number(getFieldValue([name, 'amount5']) || 0) +
                  Number(getFieldValue([name, 'amount6']) || 0)
              )}{' '}
              บาท
            </Text>
          </Col>
        </Row>
        <Form.Item style={{ paddingTop: '5px' }}>
          <Button
            style={{
              height: 50,
              background: '#164FA3',
              color: '#FCFCFC',
              borderRadius: '10px',
              ...styles.text5,
            }}
            block
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {name === 'insureExpenses' ? 'บันทึก' : 'ไปต่อ'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default OutCome
