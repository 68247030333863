import React, { Component } from 'react'
import { Input, Typography } from 'antd'
import { removecomma, addcomma } from '../helpers/data'
import { styles } from '../helpers/styles'

const { Text } = Typography

export const changeArrayData = (value, key, data, setData, type) => {
  const osb = data
  osb[key] = {
    ...data[key],
  }
  osb[key][type] = Number(value)
  setData({ ...osb })
}

function countDecimals(value) {
  if (Math.floor(value) !== value) {
    return (value.toString().split('.')[1] || '').length
  }
  return 0
}

class InputNumber extends Component {
  constructor(props) {
    super(props)

    this.toChange = this.toChange.bind(this)

    const { 'data-__meta': meta, onChange } = this.props
    const initialValue = meta ? meta.initialValue || '' : ''
    this.state = {}
    if (onChange) {
      if (initialValue !== '') {
        onChange(initialValue)
        this.state = {
          displayValue: `${Number(Number(initialValue))}`,
        }
      }
    }
  }
  toChange(e) {
    const { value } = e.target
    const {
      onChange,
      setData,
      keyArray,
      ArrayData,
      setArrayData,
      type,
      maxValue,
    } = this.props
    if (onChange) {
      if (value === '') {
        onChange(null)
        if (setData) setData(null)
        if (ArrayData && setArrayData) {
          changeArrayData(null, keyArray, ArrayData, setArrayData, type)
        }
        this.setState({ displayValue: '' })
      } else {
        const baht = Number(removecomma(value))
        if (maxValue) {
          if (baht <= maxValue) {
            if (!Number.isNaN(baht)) {
              onChange(baht)
              if (setData) setData(baht)
              if (ArrayData && setArrayData) {
                changeArrayData(baht, keyArray, ArrayData, setArrayData, type)
              }
              this.setState({ displayValue: `${removecomma(value)}` })
            } else {
              onChange(null)
              if (setData) setData(null)
              if (ArrayData && setArrayData) {
                changeArrayData(null, keyArray, ArrayData, setArrayData, type)
              }
              this.setState({ displayValue: '' })
            }
          }
        } else {
          if (!Number.isNaN(baht)) {
            onChange(baht)
            if (setData) setData(baht)
            if (ArrayData && setArrayData) {
              changeArrayData(baht, keyArray, ArrayData, setArrayData, type)
            }
            this.setState({ displayValue: `${removecomma(value)}` })
          } else {
            onChange(null)
            if (setData) setData(null)
            if (ArrayData && setArrayData) {
              changeArrayData(null, keyArray, ArrayData, setArrayData, type)
            }
            this.setState({ displayValue: '' })
          }
        }
      }
    }
  }

  handleKeyDown(e) {
    const checkNumber = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
    const value = checkNumber.includes(e.key)
    const code = e.keyCode
    if (
      !value &&
      code !== 8 &&
      code !== 37 &&
      code !== 39 &&
      code !== 9 &&
      code !== 190
    ) {
      e.preventDefault()
    }
  }

  render() {
    const { placeholder, style, disabled, value, suffix } = this.props
    const { displayValue } = this.state
    const initialValue = isNaN(parseFloat(value))
      ? ''
      : `${Number(Number(value))}`
    const _displayValue =
      (displayValue || initialValue) === ''
        ? ''
        : addcomma(displayValue || initialValue)
    return (
      <div style={{ position: 'relative' }}>
        <Input
          type="text"
          inputmode="numeric"
          pattern="\[0-9]"
          placeholder={placeholder}
          onKeyDown={this.handleKeyDown}
          onChange={this.toChange}
          value={_displayValue}
          style={style}
          disabled={disabled}
        />
        <div style={{ position: 'absolute', right: 10, top: 5 }}>
          <Text style={styles.input}>{suffix}</Text>
        </div>
      </div>
    )
  }
}

export default InputNumber
