import { useEffect, useState } from "react";
import icon1 from "../image/outcome1.svg";
import icon2 from "../image/outcome2.svg";
import icon3 from "../image/outcome3.svg";
import icon4 from "../image/outcome4.svg";
import icon5 from "../image/outcome5.svg";
import icon6 from "../image/outcome6.svg";
import Success from "./Success";
import { API_URL, postJson, patchJson } from "../helpers/api";
import OutCome from "../components/OutCome";

const Expenses = ({ user, getDataSource, postBack }) => {
  const [expenses, setExpenses] = useState({});

  const checkAmount = (title, amounts) => {
    const values = Object.keys(amounts).map((key, index) => {
      return { key: index + 1, name: title[index], amount: amounts[key] };
    });
    return values;
  };

  const submit = async (values) => {
    const {
      generalExpenses,
      commuteExpenses,
      familyExpenses,
      utilityExpenses,
      funExpenses,
    } = expenses;
    const expense = {
      generalExpenses: checkAmount(title1, generalExpenses),
      commuteExpenses: checkAmount(title2, commuteExpenses),
      familyExpenses: checkAmount(title3, familyExpenses),
      utilityExpenses: checkAmount(title4, utilityExpenses),
      funExpenses: checkAmount(title5, funExpenses),
      insureExpenses: checkAmount(title6, values),
      otherExpenses: [],
      extraExpenses: [],
    };

    const { debt, mobileNo } = user;
    const mapValues = {
      debt,
      mobileNo,
      expense,
    };
    await patchJson(`${API_URL}/debtplans`, mapValues);

    const postValues = [];
    Object.keys(expense).map((key) => {
      Object.keys(expense[key]).map((subKey) => {
        const { name, amount } = expense[key][subKey];
        const data = {
          type: "string",
          key: name,
          value: amount,
        };
        postValues.push(data);
      });
    });
    await postJson(`${postBack}`, postValues);
    setPage("success");
    window.ReactNativeWebView.postMessage("success");
  };

  useEffect(() => {
    getDataSource("expense");
  }, []);

  const [page, setPage] = useState("Expenses1");
  const title1 = [
    "ค่าอาหาร",
    "เครื่องดื่ม",
    "จ่ายตลาด",
    "กินข้าวนอกบ้าน",
    "ของใช้ในบ้าน",
    "อื่นๆ",
    "รวมรายจ่ายของกินของใช้",
  ];
  const title2 = [
    "น้ำมัน",
    "วินมอเตอร์ไซค์",
    "รถประจำทาง รถไฟฟ้า",
    "รถแทกซี่",
    "ซ่อมรถ",
    "อื่นๆ",
    "รวมรายจ่ายการเดินทาง",
  ];
  const title3 = [
    "ให้ครอบครัว",
    "เพื่อลูก",
    "กลับบ้าน",
    "ซ่อมบ้าน",
    "สัตว์เลี้ยง",
    "อื่นๆ",
    "รวมรายจ่ายครอบครัว",
  ];
  const title4 = [
    "ค่าเช่าบ้าน (ไม่ใช่ค่าผ่อน)",
    "ค่าน้ำ",
    "ค่าไฟ",
    "มือถือ",
    "อินเตอร์เนตบ้าน",
    "อื่นๆ",
    "รวมรายจ่ายสาธารณูปโภค",
  ];
  const title5 = [
    "หวย",
    "ชอปปิง",
    "บุหรี่",
    "เหล้า เบียร์",
    "ทำบุญ",
    "อื่นๆ",
    "รวมรายจ่ายความสุข",
  ];
  const title6 = [
    "ประกันสังคม",
    "ประกันชีวิต",
    "ประกันสุขภาพ",
    "รักษาพยาบาล",
    "ยา",
    "อื่นๆ",
    "รวมรายจ่ายสุขภาพและประกัน",
  ];
  const mapping = {
    Expenses1: (
      <OutCome
        pageTitle={["รายจ่าย (1/6)", "ของกินของใช้ต่อเดือน"]}
        setPage={setPage}
        title={title1}
        icon={icon1}
        nextPage="Expenses2"
        expenses={expenses}
        setExpenses={setExpenses}
        name="generalExpenses"
      />
    ),
    Expenses2: (
      <OutCome
        pageTitle={["รายจ่าย (2/6)", "การเดินทางต่อเดือน"]}
        setPage={setPage}
        title={title2}
        icon={icon2}
        backPage="Expenses1"
        nextPage="Expenses3"
        expenses={expenses}
        setExpenses={setExpenses}
        name="commuteExpenses"
      />
    ),
    Expenses3: (
      <OutCome
        pageTitle={["รายจ่าย (3/6)", "รายจ่ายครอบครัวต่อเดือน"]}
        setPage={setPage}
        title={title3}
        icon={icon3}
        backPage="Expenses2"
        nextPage="Expenses4"
        expenses={expenses}
        setExpenses={setExpenses}
        name="familyExpenses"
      />
    ),
    Expenses4: (
      <OutCome
        pageTitle={["รายจ่าย (4/6)", "สาธารณูปโภคต่อเดือน"]}
        setPage={setPage}
        title={title4}
        icon={icon4}
        backPage="Expenses3"
        nextPage="Expenses5"
        expenses={expenses}
        setExpenses={setExpenses}
        name="utilityExpenses"
      />
    ),
    Expenses5: (
      <OutCome
        pageTitle={["รายจ่าย (5/6)", "รายจ่ายความสุขต่อเดือน"]}
        setPage={setPage}
        title={title5}
        icon={icon5}
        backPage="Expenses4"
        nextPage="Expenses6"
        expenses={expenses}
        setExpenses={setExpenses}
        name="funExpenses"
      />
    ),
    Expenses6: (
      <OutCome
        pageTitle={["รายจ่าย (6/6)", "สุขภาพและประกันต่อเดือน"]}
        setPage={setPage}
        title={title6}
        icon={icon6}
        backPage="Expenses5"
        expenses={expenses}
        setExpenses={setExpenses}
        submit={submit}
        name="insureExpenses"
      />
    ),
    success: <Success />,
  };
  return mapping[page];
};

export default Expenses;

const postValues = [
  {
    type: "array",
    key: "generalExpenses",
    value: [
      {
        key: 1,
        name: "ค่าอาหาร",
        amount: 3000,
      },
      {
        key: 2,
        name: "เครื่องดื่ม",
        amount: 200,
      },
    ],
  },
  {
    type: "array",
    key: "commuteExpenses",
    value: [
      {
        key: 1,
        name: "น้ำมัน",
        amount: 1000,
      },
      {
        key: 2,
        name: "วินมอเตอร์ไซค์",
        amount: 500,
      },
    ],
  },
];
