import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Input, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, CloseCircleFilled } from "@ant-design/icons";
import "../App.css";
import invest from "../image/invest.svg";
import { styles } from "../helpers/styles";
import { API_URL, postJson } from "../helpers/api";
import { AddKeyToArrayObject } from "../helpers/data";
import HeaderIcon from "../components/HeaderIcon";
import InputNumber from "../components/InputNumber";

const { Text } = Typography;

const Property = ({ user, getDataSource, postBack }) => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    getDataSource("balanceSheets");
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const { balanceSheets } = values;
    const notEmty = balanceSheets.filter((data) => {
      const { amount } = data;
      if (amount > 0) return data;
    });
    const mapBalanceSheets = AddKeyToArrayObject(notEmty);
    const mapValues = {
      income: {},
      expense: {},
      savings: [],
      debt: { installments: [], revolvings: [] },
      mobileNo: user.mobileNo,
      faCode: "noburo",
      debtPlanStatus: "รอส่ง NCB",
      balanceSheets: mapBalanceSheets,
    };
    await postJson(`${API_URL}/debtplans`, mapValues);
    const postValues = mapBalanceSheets.map((balanceSheet) => ({
      type: "string",
      key: balanceSheet.name,
      value: balanceSheet.amount,
    }));
    await postJson(`${postBack}`, postValues);
    navigate("/success");
    window.ReactNativeWebView.postMessage("success");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="Property" style={styles.main}>
      <Row justify="center">
        <Col align="center">
          <Text
            style={{
              color: "#51546E",
              ...styles.text1,
            }}
          >
            ทรัพย์สิน (1/1)
          </Text>
        </Col>
      </Row>

      <HeaderIcon icon={invest} text="ทรัพย์สินของคุณมีอะไรบ้าง?" />

      <Row justify="center">
        <Col style={{ textAlign: "left" }}>
          <Text
            style={{
              color: "#51546E",
              ...styles.text3,
            }}
          >
            ตัวอย่าง: ที่ดิน บ้าน รถยนต์ มอเตอร์ไซค์ พระเครื่อง ทอง ตู้เย็น
            เสื้อผ้า
          </Text>
        </Col>
      </Row>

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ paddingTop: "15px" }}
      >
        <Form.List
          name="balanceSheets"
          initialValue={user?.balanceSheets || [{ name: "", amount: "" }]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  style={{
                    border: "2px solid #DEE8F8",
                    borderRadius: "16px",
                    padding: "5px",
                    marginTop: "10px",
                  }}
                >
                  <Row span={24} style={{ marginTop: "5px" }}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Text
                        style={{
                          color: "#51546E",
                          ...styles.text1,
                        }}
                      >
                        รายการที่ {name + 1}
                      </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <CloseCircleFilled onClick={() => remove(name)} />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "5px", height: 45 }}>
                    <Col span={24}>
                      <Form.Item {...restField} name={[name, "name"]}>
                        <Input
                          style={styles.input}
                          placeholder="กรอกทรัพย์สิน"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 45 }}>
                    <Col span={24}>
                      <Form.Item {...restField} name={[name, "amount"]}>
                        <InputNumber
                          style={styles.input}
                          placeholder="มูลค่าทรัพย์สิน"
                          suffix="บาท"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
              <Form.Item style={{ paddingTop: "15px", textAlign: "left" }}>
                <Button
                  style={{
                    color: "#164FA3",
                    border: "2px solid #164FA3",
                    ...styles.text4,
                  }}
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  เพิ่มรายการ
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            style={{
              height: 50,
              background: "#164FA3",
              color: "#FCFCFC",
              borderRadius: "10px",
              ...styles.text5,
            }}
            block
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Property;
