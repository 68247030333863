import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import WebFont from "webfontloader";
import "./App.css";
import Property from "./page/Property";
import Debts from "./page/Debts";
import ImportNcb from "./page/ImportNcb";
import Income from "./page/Income";
import Expenses from "./page/Expenses";
import Success from "./page/Success";
import { API_URL, getJson, postJson } from "./helpers/api";

function App() {
  return (
    <Router>
      <SomeOtherComponent />
    </Router>
  );
}

function SomeOtherComponent() {
  const queryParams = new URLSearchParams(window.location.search);
  const phone = queryParams.get("phone");
  const postBack = queryParams.get("postback");
  const [user, setUser] = useState({});
  let navigate = useNavigate();

  const haveDebtplans = async () => {
    const postValues = {
      type: "string",
      key: "result",
      value: "มีแผนปลดหนี้แล้ว",
    };
    await postJson(`${postBack}`, postValues);
    window.ReactNativeWebView.postMessage("success");
  };

  async function getDataSource(find, subFind) {
    const response = await getJson(`${API_URL}/debtplans/${phone}`);
    const { code, data } = response;
    if (code === 200) {
      setUser(data);
      if (find === "balanceSheets" && data) {
        haveDebtplans();
        navigate("/havedebtplans");
      } else if (
        find &&
        find !== "debt" &&
        Object.keys(data[find]).length > 0
      ) {
        haveDebtplans();
        navigate("/havedebtplans");
      } else if (find === "debt" && data[find][subFind].length > 0) {
        haveDebtplans();
        navigate("/havedebtplans");
      }
    }
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Kanit"],
      },
    });
    setUser({
      mobileNo: phone,
      faCode: "noburo",
    });
    getDataSource();
  }, []);

  return (
    <div className="App">
      <>
        <div className="font-loader">
          <Routes>
            <Route
              path="/"
              element={
                <Property
                  user={user}
                  getDataSource={getDataSource}
                  postBack={postBack}
                />
              }
            />
            <Route
              path="/debts"
              element={
                <Debts
                  user={user}
                  getDataSource={getDataSource}
                  postBack={postBack}
                />
              }
            />
            <Route
              path="/income"
              element={
                <Income
                  user={user}
                  getDataSource={getDataSource}
                  postBack={postBack}
                />
              }
            />
            <Route
              path="/expenses"
              element={
                <Expenses
                  user={user}
                  getDataSource={getDataSource}
                  postBack={postBack}
                />
              }
            />
            <Route
              path="/ncb"
              element={<ImportNcb user={user} postBack={postBack} />}
            />
            <Route path="/success" element={<Success />} />
            <Route
              path="/havedebtplans"
              element={<Success text="มีแผนปลดหนี้แล้ว" />}
            />
          </Routes>
        </div>
      </>
    </div>
  );
}

export default App;
