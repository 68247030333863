export const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:3001'

async function handleError(response) {
  let errorMessage = ''
  let json
  try {
    json = await response.json()
    // eslint-disable-next-line prefer-destructuring
    errorMessage = json.errorMessage
  } catch (error) {
    // api does not return error object use default statusText
    errorMessage = `
      Cannot parse response.body as json, statusText is ${response.statusText}`
  }
  if (!response.ok) {
    const error = new Error(errorMessage)
    if (json) {
      error.code = json.code
    }
    error.code = error.code || response.status
    error.errorMessage = errorMessage
    error.response = response
    throw error
  }
  return json
}

export async function postJson(url, jsonBody) {
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(jsonBody),
  })

  const json = await handleError(response)
  return json
}

export async function getJson(url) {
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
  })

  const json = await handleError(response)
  return json
}

export async function patchJson(url, jsonBody) {
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    mode: 'cors',
    body: JSON.stringify(jsonBody),
  })

  const json = await handleError(response)
  return json
}
