import { Row, Col, Image, Typography } from 'antd'
import { DemoBox } from '../helpers/component'
import { styles } from '../helpers/styles'

const { Text } = Typography

const HeaderIcon = ({ icon, text }) => {
  return (
    <Row style={{ paddingTop: '10px' }}>
      <Col>
        <div style={{ paddingTop: 10, marginRight: '10px' }}>
          <Image src={icon} />
        </div>
      </Col>
      <Col>
        <DemoBox>
          <Text
            style={{
              color: '#20202C',
              ...styles.text2,
            }}
          >
            {text}
          </Text>
        </DemoBox>
      </Col>
    </Row>
  )
}

export default HeaderIcon
