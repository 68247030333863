import { useEffect, useState } from "react";
import { Form, Row, Col, Input, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { color, styles } from "../helpers/styles";
import income from "../image/income.svg";
import { API_URL, postJson, patchJson } from "../helpers/api";
import HeaderIcon from "../components/HeaderIcon";
import { addcomma } from "../helpers/data";
import InputNumber from "../components/InputNumber";

const { Text } = Typography;

const Income = ({ user, getDataSource, postBack }) => {
  const [loading, setLoading] = useState(false);
  const [salary, setSalary] = useState(0);
  const [extraIncome, setExtraIncon] = useState(0);
  const [bonus, setBonus] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    getDataSource("income");
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const { salary, extraIncome, bonus } = values;
    const incomes = [];
    const extraIncomes = [];
    if (salary) incomes.push({ key: 1, name: "ฐานเงินเดือน", amount: salary });
    if (extraIncome)
      incomes.push({
        key: 2,
        name: "รายได้พิเศษต่อเดือน",
        amount: extraIncome,
      });
    if (bonus)
      extraIncomes.push({
        key: 1,
        name: "โบนัสปีล่าสุด",
        amount: bonus,
      });
    const income = {
      incomes,
      extraIncomes,
    };
    const { debt, mobileNo } = user;
    const mapValues = {
      debt,
      mobileNo,
      income,
    };
    await patchJson(`${API_URL}/debtplans`, mapValues);

    const postValues = incomes.concat(extraIncomes).map((income) => ({
      type: "string",
      key: income.name,
      value: income.amount,
    }));
    await postJson(`${postBack}`, postValues);
    navigate("/success");
    window.ReactNativeWebView.postMessage("success");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={styles.main}>
      <Row>
        <Col span={24} align="center">
          <Text
            style={{
              color: "#51546E",
              ...styles.text1,
            }}
          >
            รายรับ (1/1)
          </Text>
        </Col>
      </Row>

      <HeaderIcon icon={income} text="รายรับต่อเดือน" />

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ paddingTop: "15px" }}
        align="left"
      >
        <Row style={{ paddingTop: "5px" }}>
          <Col span={24}>
            <Text style={styles.text1}>ฐานเงินเดือน (บาท) *</Text>
            <Form.Item
              name={"salary"}
              style={{ paddingTop: "5px" }}
              rules={[{ required: true, message: "กรุณากรอกฐานเงินเดือน" }]}
            >
              <InputNumber style={styles.input} setData={setSalary} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: "5px" }}>
          <Col span={24}>
            <Text style={styles.text1}>รายได้พิเศษต่อเดือน (บาท)</Text>
            <Form.Item name={"extraIncome"} style={{ paddingTop: "5px" }}>
              <InputNumber
                style={styles.input}
                placeholder="เช่น โอที คอมมิชชัน รายได้เสริม"
                setData={setExtraIncon}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: "5px" }}>
          <Col span={24}>
            <Text style={styles.text1}>โบนัสปีล่าสุด (บาท)</Text>
            <Form.Item name={"bonus"} style={{ paddingTop: "5px" }}>
              <InputNumber style={styles.input} setData={setBonus} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <Text style={styles.text1}>รวมรายรับต่อเดือน</Text>
          </Col>
          <Col span={9} align="right">
            <Text style={styles.text1}>
              {addcomma(
                (
                  Number(salary) +
                  Number(extraIncome) +
                  Number(bonus) / 12
                ).toFixed(0)
              )}{" "}
              บาท
            </Text>
          </Col>
        </Row>
        <Form.Item style={{ paddingTop: "5px" }}>
          <Button
            style={{
              height: 50,
              background: salary ? color.primary : color.grey,
              color: "#FCFCFC",
              borderRadius: "10px",
              ...styles.text5,
            }}
            block
            type="primary"
            htmlType="submit"
            disabled={salary ? false : true}
            loading={loading}
          >
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Income;
