import { useEffect, useState } from "react";
import moment from "moment";
import DebtBAAC from "../components/DebtBAAC";
import DebtsOther from "../components/DebtsOther";
import DebtsInformal from "../components/DebtsInformal";
import Success from "./Success";
import { AddKeyToArrayObject } from "../helpers/data";
import { API_URL, postJson, patchJson } from "../helpers/api";
import QuizDebts from "../components/QuizDebts";

const Debts = ({ user, getDataSource, postBack }) => {
  const [page, setPage] = useState("debtsBAAC");
  const [debtsBAAC, setDebtsBAAC] = useState([{}]);
  const [debtsOther, setDebtsOther] = useState([{}]);

  const checkDebts = (debts) => {
    const values = debts.filter((debt) => {
      const { installAmount, amount } = debt;
      if (installAmount || amount) return debt;
    });
    return values;
  };

  const submitDebt = async (debtsInformal) => {
    let installments = [];
    let revolvings = [];
    const mapInstallments = checkDebts(debtsBAAC.concat(debtsOther));
    const mapRevolvings = checkDebts(debtsInformal);
    if (mapInstallments.length > 0) {
      installments = AddKeyToArrayObject(mapInstallments);
      installments = installments.map((installment) => {
        const { type, installAmount, installTerm, canStop, isPastDue } =
          installment;
        return {
          ...installment,
          type: "เงินผ่อน (อื่นๆ)",
          amount: installAmount * installTerm,
          canStop: canStop ? "ได้" : "ไม่ได้",
          isPastDue: isPastDue ? "ค้าง" : "ไม่ค้าง",
        };
      });
    }
    if (mapRevolvings.length > 0) {
      revolvings = AddKeyToArrayObject(mapRevolvings);
      revolvings = revolvings.map((revolving) => {
        const { canStop, isPastDue } = revolving;
        return {
          ...revolving,
          type: "นอกระบบไม่หมุน",
          canStop: canStop ? "ได้" : "ไม่ได้",
          isPastDue: isPastDue ? "ค้าง" : "ไม่ค้าง",
        };
      });
    }
    const debt = {
      installments: installments.length === 0 ? [{}] : installments,
      revolvings: revolvings.length === 0 ? [{}] : revolvings,
    };

    const asOfMonth = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const missionStartMonth = moment()
      .add(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const { mobileNo } = user;
    const mapValues = {
      mobileNo,
      debt,
      asOfMonth,
      missionStartMonth,
    };
    await patchJson(`${API_URL}/debtplans`, mapValues);

    const postValues = installments.concat(revolvings).map((debt) => ({
      type: "object",
      key: debt.name,
      value: debt,
    }));
    await postJson(`${postBack}`, postValues);
    setPage("success");
    window.ReactNativeWebView.postMessage("success");
  };

  useEffect(() => {
    getDataSource("debt", "installments");
  }, []);

  const title = [
    "1. ข้อใดคือ หนี้ในระบบ ที่มีข้อมูลในเครดิตบูโร",
    "2. เมื่อเรา “ผ่อน” ซื้อของ เช่น มือถือ มอเตอร์ไซค์ เครื่องใช้ไฟฟ้า ทอง โดยใช้บัตรประชาชนใบเดียว นับเป็นหนี้ในหมวดหมู่ใด",
    "3. หนี้ที่ไม่ต้องกรอกในด่านภารกิจทำแผนปลดหนี้ คือ",
    "4. ทำไมถึงต้องส่งข้อมูลเครดิตเข้ามา",
  ];
  const answer = [
    "หนี้ในระบบ คือ หนี้ที่กู้ยืมผ่านธนาคาร ไม่ว่าจะไปกู้ด้วยตัวเอง หรือซื้อของผ่านบัตรเครดิต/บัตรกดเงินสด เช่น สินเชื่อบ้าน รถ บัตรเครดิต บัตรกดเงิน",
    "หนี้ผ่อน คือ หนี้ที่เราผ่อนจ่ายทุกเดือนเท่ากัน มีระยะเวลาที่ชัดเจน กับร้านค้า ที่ไม่ใช่ธนาคาร หรือสถาบันการเงิน เช่น โทรศัพท์ มอเตอร์ไซค์ เครื่องใช้ไฟฟ้า ทอง เป็นต้น",
    "หนี้ในระบบ เช่น หนี้บ้าน หนี้รถ หนี้บัตรเครดิต หนี้บัตรกดเงินสด สินเชื่อส่วนบุคคล ไม่ต้องกรอกเข้ามา เพราะทางโนบูโรใช้ข้อมูลที่ลูกค้าส่งผลข้อมูลเครดิตอยู่แล้ว",
    "ข้อมูลเครดิต จาก​​​​​​​​​​​​​​​​​​เครดิตบูโร คือ ประวัติข้อมูลสินเชื่อ และการชำระหนี้ในระบบ (หนี้กับสถาบันการเงิน) ซึ่งทางโนบูโรนำมาใช้เพื่อทำแผนปลดหนี้ เพื่อวางแผนจ่ายหนี้ และให้จัดการหนี้แต่ละตัวได้เร็วที่สุด โดยไม่มีผลต่อการอนุมัติสินเชื่อ",
  ];
  const questions1 = [
    "หนี้กยศ. กรอ. / หนี้สหกรณ์",
    "หนี้บ้าน หนี้รถ หนี้บัตรเครดิต หนี้เกิดขึ้นกับธนาคาร หรือสถาบันการเงิน",
    "เล่นแชร์กับเพื่อน ไม่มีสัญญาใดๆ",
    "หนี้ยายแดง ดอกเบี้ย ร้อยละ 10 ต่อเดือน",
  ];
  const questions2 = [
    "หนี้ในระบบ",
    "หนี้กยศ. กรอ. / หนี้สหกรณ์",
    "หนี้แชร์ / หนี้ผ่อนอื่นๆ",
    "หนี้นอกระบบ",
  ];
  const questions3 = [
    "หนี้บ้าน หนี้รถ ที่ผ่อนกับธนาคาร หรือสถาบันการเงิน",
    "หนี้บัตรเครดิต ที่ไปรูดซื้อมือถือ",
    "หนี้บัตรกดเงินสด ที่ถอนออกมาหมุนใช้ในแต่ละเดือน",
    "ถูกทุกข้อ",
  ];
  const questions4 = [
    "ให้โนบูโรทำแผนปลดหนี้",
    "ข้อมูลหนี้ในระบบ ที่มีกับสถาบันจะถูกต้อง",
    "เพื่อทราบลำดับการจ่ายหนี้แต่ละตัว",
    "ถูกทุกข้อ",
  ];

  const mapping = {
    quiz1Debts: (
      <QuizDebts
        title={title[0]}
        questions={questions1}
        answer={answer[0]}
        correct={2}
        setPage={setPage}
        nextPage={"quiz2Debts"}
      />
    ),
    quiz2Debts: (
      <QuizDebts
        title={title[1]}
        questions={questions2}
        answer={answer[1]}
        correct={3}
        setPage={setPage}
        nextPage={"quiz3Debts"}
      />
    ),
    quiz3Debts: (
      <QuizDebts
        title={title[2]}
        questions={questions3}
        answer={answer[2]}
        correct={4}
        setPage={setPage}
        nextPage={"quiz4Debts"}
      />
    ),
    quiz4Debts: (
      <QuizDebts
        title={title[3]}
        questions={questions4}
        answer={answer[3]}
        correct={4}
        setPage={setPage}
        nextPage={"debtsBAAC"}
      />
    ),
    debtsBAAC: (
      <DebtBAAC setPage={setPage} debts={debtsBAAC} setDebts={setDebtsBAAC} />
    ),
    debtsOther: (
      <DebtsOther
        setPage={setPage}
        debts={debtsOther}
        setDebts={setDebtsOther}
      />
    ),
    debtsInformal: (
      <DebtsInformal setPage={setPage} debts={[{}]} submitDebt={submitDebt} />
    ),
    success: <Success />,
  };
  return mapping[page];
};

export default Debts;
