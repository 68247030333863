import { useState } from 'react'
import { Button, Typography, Radio, Space } from 'antd'
import { styles } from '../helpers/styles'

const { Text } = Typography

const QuizDebts = ({
  title,
  questions,
  answer,
  correct,
  setPage,
  nextPage,
}) => {
  const [choice, setChoice] = useState(0)
  const [reply, setReply] = useState(0)

  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setReply(0)
    setChoice(e.target.value)
  }

  return (
    <div className="DebtBAAC" style={styles.main}>
      <div style={{ textAlign: 'left' }}>
        <Text style={{ ...styles.text2 }}>{title}</Text>
      </div>

      <div style={{ textAlign: 'left', paddingTop: 50 }}>
        <Radio.Group onChange={onChange} value={choice}>
          <Space direction="vertical">
            {questions.map((value, index) => (
              <>
                <Radio value={index + 1}>
                  <Text style={styles.text1}>{value}</Text>
                </Radio>
              </>
            ))}
          </Space>
        </Radio.Group>
      </div>

      <div>
        {reply > 0 ? (
          <div
            style={{
              textAlign: 'left',
              marginTop: 30,
              padding: 10,
              border: '2px solid #DEE8F8',
              borderRadius: '16px',
              background: reply === correct ? '#D4EFDF' : '#FADBD8',
            }}
          >
            <Text style={styles.text1}>{answer}</Text>
          </div>
        ) : (
          ''
        )}
      </div>

      <div style={{ marginTop: 20 }}>
        <Button
          style={{
            height: 50,
            background: '#164FA3',
            color: '#FCFCFC',
            borderRadius: '10px',
            ...styles.text5,
          }}
          onClick={() => {
            if (reply === 0 || reply !== correct) {
              setReply(choice)
            } else if (reply === correct && reply === choice) {
              setChoice(0)
              setReply(0)
              setPage(nextPage)
            }
          }}
          block
        >
          {reply === correct
            ? 'ไปต่อ'
            : reply === 0
            ? 'ส่งคำตอบ'
            : 'ตอบผิด กรุณาตอบใหม่'}
        </Button>
      </div>
    </div>
  )
}

export default QuizDebts
