import { Row, Typography, Image } from 'antd'
import { styles, color } from '../helpers/styles'
import noburin from '../image/noburin.svg'

const { Text } = Typography

const Success = ({ text = 'บันทึกสำเร็จแล้ว' }) => {
  return (
    <div
      style={{
        ...styles.main,
        backgroundColor: color.primary,
        height: '100vh',
      }}
    >
      <br />
      <Row justify="center">
        <Text style={{ ...styles.text2, color: color.lightBlue }}>{text}</Text>
      </Row>
      <br />
      <br />
      <Row justify="center">
        <Image src={noburin} />
      </Row>
      <br />
      <Row justify="center">
        <Text style={{ ...styles.normal2, color: color.white }}>
          ปิดหน้านี้ เพื่อตะลุยด่านถัดไปได้เลย
        </Text>
      </Row>
    </div>
  )
}

export default Success
