import { useState } from "react";
import { Row, Col, Form, Button, Typography } from "antd";
import {
  PlusOutlined,
  CloseCircleFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { styles } from "../helpers/styles";
import expends from "../image/debtsOther.svg";
import HeaderIcon from "../components/HeaderIcon";
import InputNumber from "../components/InputNumber";
import { addcomma } from "../helpers/data";
import InputText from "../components/InputText";

const { Text } = Typography;

const DebtsOther = ({ setPage, debts, setDebts }) => {
  const [calAmount, setCalAmount] = useState([
    { name: null, amount: 0, term: 0 },
  ]);

  const onFinish = async (values) => {
    const { debtsOther } = values;
    if (debtsOther.length > 0) {
      const mapDebts = debtsOther.map((debt) => {
        const { installAmount } = debt;
        return {
          ...debt,
          actualPayment: installAmount,
          type: "เงินผ่อน (อื่นๆ)",
          subType: "หนี้แชร์ / หนี้ผ่อนอื่นๆ",
          canStop: false,
          isPastDue: false,
        };
      });
      setDebts(mapDebts);
    }
    setPage("debtsInformal");
    window.scrollTo(0, 0);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="DebtsOther" style={styles.main}>
      <Row>
        <Col span={6} align="left">
          <ArrowLeftOutlined
            onClick={() => {
              setPage("debtsBAAC");
            }}
          />
        </Col>
        <Col span={12} align="center">
          <Text
            style={{
              color: "#51546E",
              ...styles.text1,
            }}
          >
            หนี้สิน (2/3)
          </Text>
        </Col>
      </Row>

      <HeaderIcon icon={expends} text="หนี้แชร์ / หนี้ผ่อนอื่นๆ" />

      <Row justify="center">
        <Col style={{ textAlign: "left" }}>
          <Text
            style={{
              color: "#51546E",
              ...styles.text3,
            }}
          >
            ตัวอย่าง: ผ่อนตู้เย็น / ทีวี / มือถือ กับร้านค้าที่ไม่อยู่ในระบบ
            หรือไม่อยู่ในรายละเอียดข้อมูลเครดิต
          </Text>
        </Col>
      </Row>

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ paddingTop: "15px" }}
      >
        <Form.List name="debtsOther" initialValue={debts}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  style={{
                    border: "2px solid #DEE8F8",
                    borderRadius: "16px",
                    padding: "5px",
                    marginTop: "10px",
                  }}
                >
                  <Row span={24} style={{ marginTop: "5px" }}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Text
                        style={{
                          color: "#51546E",
                          ...styles.text1,
                        }}
                      >
                        รายการที่ {name + 1}
                      </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <CloseCircleFilled
                        onClick={() => {
                          const osb = calAmount;
                          delete osb[key];
                          setCalAmount(osb);
                          remove(name);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "5px", height: 55 }}>
                    <Col span={24}>
                      <Form.Item {...restField} name={[name, "name"]}>
                        <InputText
                          style={styles.input}
                          placeholder="กรอกชื่อหนี้"
                          keyArray={key}
                          ArrayData={calAmount}
                          setArrayData={setCalAmount}
                          type="name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 55 }}>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "installAmount"]}
                        rules={[
                          {
                            required:
                              calAmount[key]?.name || calAmount[key]?.term
                                ? true
                                : false,
                            message: "ใส่ยอดชำระต่อเดือน",
                          },
                        ]}
                      >
                        <InputNumber
                          style={styles.input}
                          placeholder="ยอดชำระต่อเดือน"
                          suffix="บาท"
                          keyArray={key}
                          ArrayData={calAmount}
                          setArrayData={setCalAmount}
                          type="amount"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 45 }}>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "installTerm"]}
                        rules={[
                          {
                            required:
                              calAmount[key]?.name || calAmount[key]?.amount
                                ? true
                                : false,
                            message: "ใส่จำนวนงวด",
                          },
                        ]}
                      >
                        <InputNumber
                          style={styles.input}
                          placeholder="เหลือกี่งวด"
                          suffix="งวด"
                          keyArray={key}
                          ArrayData={calAmount}
                          setArrayData={setCalAmount}
                          type="term"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: "10px" }}>
                    <Text style={styles.text1}>
                      ยอดหนี้คงเหลือ:{" "}
                      {calAmount[key]?.amount && calAmount[key]?.term
                        ? addcomma(calAmount[key].amount * calAmount[key].term)
                        : ""}
                    </Text>
                  </Row>
                </div>
              ))}
              <Form.Item style={{ paddingTop: "15px", textAlign: "left" }}>
                <Button
                  style={{
                    color: "#164FA3",
                    border: "2px solid #164FA3",
                    ...styles.text4,
                  }}
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  เพิ่มรายการ
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            style={{
              height: 50,
              background: "#164FA3",
              color: "#FCFCFC",
              borderRadius: "10px",
              ...styles.text5,
            }}
            block
            type="primary"
            htmlType="submit"
          >
            ไปต่อ
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DebtsOther;
