import React, { Component } from 'react'
import { Input, Typography } from 'antd'
import { styles } from '../helpers/styles'

const { Text } = Typography

export const changeArrayData = (value, key, data, setData, type) => {
  const osb = data
  osb[key] = {
    ...data[key],
  }
  osb[key][type] = value
  setData({ ...osb })
}

class InputText extends Component {
  constructor(props) {
    super(props)

    this.toChange = this.toChange.bind(this)

    const { 'data-__meta': meta, onChange } = this.props
    const initialValue = meta ? meta.initialValue || '' : ''
    this.state = {}
    if (onChange) {
      if (initialValue !== '') {
        onChange(initialValue)
        this.state = {
          displayValue: `${initialValue}`,
        }
      }
    }
  }
  toChange(e) {
    const { value } = e.target
    const { onChange, setData, keyArray, ArrayData, setArrayData, type } =
      this.props
    if (onChange) {
      if (value === '') {
        onChange(null)
        if (setData) setData(null)
        if (ArrayData && setArrayData) {
          changeArrayData(null, keyArray, ArrayData, setArrayData, type)
        }
        this.setState({ displayValue: '' })
      } else {
        onChange(value)
        if (setData) setData(value)
        if (ArrayData && setArrayData) {
          changeArrayData(value, keyArray, ArrayData, setArrayData, type)
        }
        this.setState({ displayValue: `${value}` })
      }
    }
  }

  render() {
    const { placeholder, style, disabled, value, suffix } = this.props
    const { displayValue } = this.state
    const initialValue = value
    const _displayValue =
      (displayValue || initialValue) === '' ? '' : displayValue || initialValue
    return (
      <div style={{ position: 'relative' }}>
        <Input
          type="text"
          placeholder={placeholder}
          onChange={this.toChange}
          value={_displayValue}
          style={style}
          disabled={disabled}
        />
        <div style={{ position: 'absolute', right: 10, top: 5 }}>
          <Text style={styles.input}>{suffix}</Text>
        </div>
      </div>
    )
  }
}

export default InputText
