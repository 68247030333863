export const AddKeyToArrayObject = (array) => {
  const values = []
  for (let i = 0; i < array.length; i += 1) {
    const data = {
      key: i + 1,
      ...array[i],
    }
    values.push(data)
  }
  return values
}

export const addcomma = (number) => {
  const parts = `${number}`.split('.')
  parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const removecomma = (number) => {
  let removed = number
  for (let i = 0; i < String(number).length / 3; i += 1) {
    removed = `${removed}`.replace(',', '')
  }
  return removed
}
